import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { overlay } from "../styles/util"
import { css } from "@emotion/core"

const Music = ({ data }) => (
  <Layout>
    <SEO title="Music" />
    <Img css={overlay} alt="Photo of Hunter playing a guitar" fluid={data.file.childImageSharp.fluid} />
    <section css={styles.section}>
      <p>
        Here is a smattering of the various music I have made. I wrote and recorded for a while as The Tourist when I lived in North Carolina.
        I've cut three records under that name and am working on a fourth presently. I've also written some music for some short movies, video games
        and some other things I don't remember why. All of that stuff is below. Enjoy!
      </p>
    </section>
    <section css={styles.section}>
      <iframe css={styles.bandcampWidget} title="Not Nor, Nor Ever by The Tourist" src="https://bandcamp.com/EmbeddedPlayer/album=4042372021/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="http://thetouristnc.bandcamp.com/album/not-now-nor-ever">Not Now, Nor Ever by The Tourist</a></iframe>
      <iframe css={styles.bandcampWidget} title="The answer, of course, is of course. by The Tourist" src="https://bandcamp.com/EmbeddedPlayer/album=2041340718/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="http://thetouristnc.bandcamp.com/album/the-answer-of-course-is-of-course">The answer, of course, is of course. by The Tourist</a></iframe>
      <iframe css={styles.bandcampWidget} title="Oeuvre and Out by The Tourist" src="https://bandcamp.com/EmbeddedPlayer/album=3615190195/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="http://thetouristnc.bandcamp.com/album/oeuvre-out">Oeuvre &amp; Out by The Tourist</a></iframe>
    </section>
    <section css={styles.section}>
      <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" title="Music for Movies by Hunter MacDermut" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/805567956&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" title="Music for Video Games by Hunter MacDermut" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/792910014&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" title="Random Music by Hunter MacDermut" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/805568610&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    </section>
  </Layout>
)

const styles = {
  section: css`
    font-family: 'Open Sans', sans-serif;
    padding: 16px;
  `,
  audioContainer: css`
    padding: 16px;
    border-top: 2px solid steelblue;

    audio {
      width: 100%;
      max-width: 320px;
      margin-bottom: 1.45rem;
    }

    p {
      margin-bottom: 0;
    }
  `,
  bandcampWidget: css`
    border: 0;
    width: 100%;
    height: 42px;
  `,
}

export default Music

export const query = graphql`
  query {
    file(relativePath: { eq: "guitar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
